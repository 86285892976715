// store.js
//import { reactive } from 'vue' // 2.6 last vue version before change to reactive
import Vue from 'vue'
import axios from 'axios'

export const store = Vue.observable({
  // [[globals]]
  propsToSearch: [],
  tableColumns: [],
  colWidthDict: false,
  ogPropsToSearch: false,
  ogTableColumns: false,
  userConfirm: false,
  irisData: false,
  reset_pass: false,
  customQuery: false,
  irisLoader: false,
  aggregateSelected: 'total',
  companySelected: 'all',
  siteSelected: ['all'],
  deviceSelected: 'all',
  productSelected: ['all'],
  subProductSelected: ['all'],
  exportRows: [],
  mundialData: [],
  irisText: 'Last 30 Days',
  irisDateRange: [],
  irisMinMaxDate: [],
  customMinMaxDate: [],
  // END [[globals]]
  status_dict: {
    1:'OK',
    2:'Data Delay',
    3:'Under Maintenance',
    4:'Rebuilding Database',
  },
  adminRemoveArr: ['ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete'],
  irisRemoveArr: ['inter','ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete'],
  clientRemoveArr: ['billing_entity','vertical','lang','inter','ivt_adreq','network_adreq','pre_view_imp','view_imp','video_start','video_complete'],
  summarizeRows(tableRows, dimsArr, metricSet) {
    const summaryDict = {};
    let summaryArr = [],
    dailyRows = JSON.parse(JSON.stringify(tableRows)),
    dailyLen = dailyRows.length;

    for (let r = 0; r < dailyLen; r++) {

      // Create new tupleKey
      const cleanRow = dailyRows[r];
      delete cleanRow['date']

      let tmpArr = [], tupleArr = [];

      // Parse through dims & create tuples
      for (var d = 0; d < dimsArr.length; d++) {

        if (dimsArr[d] == 'date') { continue; }

        const tmpDim = dimsArr[d],
        tmpVal = cleanRow[tmpDim];

        tupleArr.push(tmpVal)
      }
      let tmpTuple = tupleArr.join('___');

      // Compile summary
      if (!summaryDict.hasOwnProperty( tmpTuple )) {
        summaryDict[tmpTuple] = summaryArr.length;
        summaryArr.push(cleanRow)
      } else {
        const tmpIdx = summaryDict[tmpTuple];
        for (const metric of metricSet.values()) {
          summaryArr[tmpIdx][metric] += cleanRow[metric]
        }
      }
    }
    return summaryArr
  },
  formatDateTime(input) {
    const date = new Date(input),
    year = date.getFullYear(),
    month = String(date.getMonth() + 1).padStart(2, '0'),
    day = String(date.getDate()).padStart(2, '0');

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0'),
    seconds = String(date.getSeconds()).padStart(2, '0'),
    ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert 0 to 12 for 12-hour clock
    hours = hours % 12 || 12;
    const strHours = String(hours).padStart(2, '0');

    return `${year}-${month}-${day} ${strHours}:${minutes}:${seconds}${ampm}`;
  },
  sanitizeMetrics(cleanRows, metricSet, summaryBool) {
    const math_round = Math.round,
    ogRows = JSON.parse(JSON.stringify(cleanRows)),
    colWidthDict = {}, colSet = new Set(Object.keys(cleanRows[0])),
    dimSet = new Set([...colSet].filter(x => !metricSet.has(x))),
    cleanLen = cleanRows.length, formatDateTime = this.formatDateTime;

    //console.log({dimSet,metricSet,store:this})

    if (cleanLen) {
      for (let c = 0; c < cleanLen; c++) {
        const tmpRow = cleanRows[c],
        ogRow = ogRows[c], metricArr = [...metricSet],
        metricLen = metricArr.length;


        //console.log({tmpRow,ogRow})


        for (let m = 0; m < metricLen; m++) {
          const metric = metricArr[m];

          switch (metric) {
            case 'adreq':
              tmpRow.adreq = ogRow.adreq ? ogRow.adreq.toLocaleString("en-US") : 0
              break;
            case 'mon_adreq':
              tmpRow.mon_adreq = ogRow.mon_adreq ? ogRow.mon_adreq.toLocaleString("en-US") : 0
              break;
            case 'imp':
              tmpRow.imp = ogRow.imp ? ogRow.imp.toLocaleString("en-US") : 0
              break;
            case 'revenue':
              tmpRow.revenue = ogRow.imp && ogRow.revenue && !(ogRow.revenue < 0) ? `${new Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD'}).format(ogRow.revenue)}` : '$0.00'
              //tmpRow.revenue = ogRow.revenue ? `$${ogRow.revenue.toFixed(2)}` : '$0.00'
              break;
            case 'ivt_rate':
              if (summaryBool) {
                const tmp_ivt = parseFloat( ((100 * ogRow.ivt_adreq) / (ogRow.adreq - ogRow.network_adreq)).toFixed(1) ),
                boolCheck = !(tmp_ivt > 100 || tmp_ivt < 0) ? tmp_ivt : 0;

                tmpRow.ivt_rate = ogRow.adreq && ogRow.ivt_adreq ? `${boolCheck}%` : ''
              } else {
                tmpRow.ivt_rate = ogRow.adreq && ogRow.ivt_rate ? `${ !(ogRow.ivt_rate > 100 || ogRow.ivt_rate < 0) ? ogRow.ivt_rate : 0 }%` : ''
              }
              break;
            case 'viewability':
              if (summaryBool) {
                const tmp_viewability = parseFloat( (100 * ogRow.view_imp / ogRow.pre_view_imp).toFixed(1) ),
                boolCheck = !(tmp_viewability > 100 || tmp_viewability < 0) ? tmp_viewability : 0;

                tmpRow.viewability = ogRow.imp && ogRow.pre_view_imp ? `${boolCheck}%` : ''
              } else {
                tmpRow.viewability = ogRow.imp && ogRow.viewability ? `${ !(ogRow.viewability > 100 || ogRow.viewability < 0) ? ogRow.viewability : 0 }%` : ''
              }
              break;
            case 'vcr':
              if (summaryBool) {
                const tmp_vcr = parseFloat( (100 * ogRow.video_complete / ogRow.video_start).toFixed(1) ),
                boolCheck = !(tmp_vcr > 100 || tmp_vcr < 0) ? tmp_vcr : 0;

                tmpRow.vcr = ogRow.imp && ogRow.video_start ? `${boolCheck}%` : ''
              } else {
                tmpRow.vcr = ogRow.imp && ogRow.vcr ? `${ !( ogRow.vcr > 100 || ogRow.vcr < 0 ) ? ogRow.vcr : 0 }%` : ''
              }
              break;
            case 'fill_rate':
              if (summaryBool) {
                const tmp_fill_rate = parseFloat( (100 * ogRow.imp / ogRow.mon_adreq).toFixed(1) ),
                boolCheck = !(tmp_fill_rate < 0) ? tmp_fill_rate : 0;

                tmpRow.fill_rate = ogRow.imp && ogRow.mon_adreq ? `${ !(boolCheck > 100) ? boolCheck : 100 }%` : ''
              } else {
                tmpRow.fill_rate = ogRow.imp && ogRow.fill_rate ? `${ !(ogRow.fill_rate < 0) ? ogRow.fill_rate : 0 }%` : ''
              }
              break;
            case 'inter':
              tmpRow.inter = ogRow.inter ? 'Yes' : ''
              break;
            case 'last_login':
              tmpRow.last_login = ogRow.last_login ? ogRow.last_login.split('T')[0]  : ''
              break;
            case 'is_active':
              tmpRow.is_active = ogRow.is_active ? 'Active' : ''
              break;
            case 'datetime':
              tmpRow.datetime = ogRow.datetime ? formatDateTime(ogRow.datetime) : ''
              break;
            default:
              continue
          }

          // Populate metric widths
          const tmpLen = Number.isInteger(tmpRow[metric]) ? tmpRow[metric].toString().length : tmpRow[metric].length,
          titleLen = this.colsDict.hasOwnProperty(metric) && this.colsDict[metric].length > 12 ? this.colsDict[metric].length : 12;

          //console.log({metric, tmpLen, titleLen})

          if (!colWidthDict.hasOwnProperty(metric)) colWidthDict[metric] = 0;
          if (tmpLen > colWidthDict[metric] || titleLen > colWidthDict[metric]) colWidthDict[metric] = titleLen > tmpLen ? titleLen * 10 + 60 : tmpLen * 10 + 80;
          if (colWidthDict[metric] > 600) colWidthDict[metric] = 180;
        }

        const dimArr = [...dimSet],
        dimLen = dimArr.length;
        for (let d = 0; d < dimLen; d++) {

          // Populate dim widths
          const dim = dimArr[d], tmpLen = Number.isInteger(tmpRow[dim]) ? tmpRow[dim].toString().length : tmpRow[dim].length,
          titleLen = this.colsDict.hasOwnProperty(dim) && this.colsDict[dim].length > 12 ? this.colsDict[dim].length : 12;

          //console.log({dim, tmpLen, titleLen})

          if (!colWidthDict.hasOwnProperty(dim)) colWidthDict[dim] = 0;
          if (tmpLen > colWidthDict[dim] || titleLen > colWidthDict[dim]) colWidthDict[dim] = titleLen > tmpLen ? titleLen * 10 + 60 : tmpLen * 10 + 80;
          if (colWidthDict[dim] > 600) colWidthDict[dim] = 180;
        }

      }
    }
    this.colWidthDict = colWidthDict

    //console.log({colWidthDict})

    return cleanRows
  },
  filterMundial(filters) {
    const reformatDate = this.reformatDate, self = this,
    filterLen = filters.length;

    //console.log({filters, sessionFilters:self.irisData.sessionFilters, customQuery:self.customQuery})
    //console.log({mundial:this.irisData.mundial})

    return (this.customQuery ? this.irisData.custom : this.irisData.mundial).filter(function(val) {


      //console.log({val, filters, length:filters.length})


      let bool = true;
      for(let i = 0; i < filterLen; i++) {
          const tmpKey = filters[i][0], tmpVal = filters[i][1];

        //console.log({val:val[filters[i][0]],filter:filters[i]})


        if(tmpKey === 'date') {
          const objCheck = tmpVal[0] instanceof Date,
          sDate = objCheck ? parseInt(tmpVal[0].toISOString().split('T')[0].replaceAll('-','')) + 1 : parseInt(tmpVal[0].replaceAll('-','')),
          eDate = objCheck ? parseInt(tmpVal[1].toISOString().split('T')[0].replaceAll('-','')) + 1 : parseInt(tmpVal[1].replaceAll('-','')),
          tmpDate = reformatDate(val[tmpKey]);



          //console.log({sDate,eDate,tmpDate,objCheck})



          if (tmpDate < sDate - 1) {
            bool = false;
          }
          if (tmpDate > eDate - 1) {
            bool = false;
          }
          if (self.customQuery && sDate !== eDate && tmpDate === sDate) {
            bool = true;
          }
          if (self.irisData.sessionFilters && self.customQuery && tmpDate === sDate - 1) {
            bool = true;
          }
          if (!objCheck && tmpDate === parseInt(
            new Date(new Date(tmpVal[0]).getTime() - 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')
          )) {
            bool = true;
          }
          if (objCheck || self.customQuery) {
            self.irisText = 'Custom Range';
          }
        } else if (Array.isArray(tmpVal) && tmpVal.length) {
          const boolSet = new Set(tmpVal), loopVal = val[tmpKey].toLowerCase(), allFlag = boolSet.size === 1 && boolSet.has('all');
          if (!allFlag && !boolSet.has(loopVal)) bool = false;
        } else if (tmpVal.length && tmpVal != 'all' && val[tmpKey].toLowerCase() != tmpVal) {

          //console.log({localVal:val[filters[i][0]],localFilter:filters[i][1]})

          bool = false;
        }
      }

      return bool;

    })
  },
  // UPDATE: need to include changes from filterMundial
  filterCharts(filters) {
    const reformatDate = this.reformatDate, self = this,
    filterLen = filters.length;

    //console.log({filters})
    //console.log({mundial:this.irisData.mundial})

    return this.irisData.mundial_charts.filter(function(val) {


      //console.log({val, filters, length:filters.length})


      let bool = true;
      for(let i = 0; i < filterLen; i++) {


        //console.log({val:val[filters[i][0]],filter:filters[i]})


        if(filters[i][0] == 'date') {
          const objCheck = filters[i][1][0] instanceof Date,
          sDate = objCheck ? parseInt(new Date(filters[i][1][0].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][0].replaceAll('-','')),
          eDate = objCheck ? parseInt(new Date(filters[i][1][1].getTime() + 3600 * 1000 * 24).toISOString().split('T')[0].replaceAll('-','')) : parseInt(filters[i][1][1].replaceAll('-','')),
          tmpDate = reformatDate(val[filters[i][0]]);



          //console.log({sDate,eDate,tmpDate})



          if (tmpDate < sDate - 1) {
            bool = false;
          }
          if (tmpDate > eDate - 1) {
            bool = false;
          }
          if (objCheck) {
            self.irisText = 'Custom Range';
          }
        } else if (filters[i][1].length && filters[i][1] != 'all' && val[filters[i][0]].toLowerCase() != filters[i][1]) {

          //console.log({localVal:val[filters[i][0]],localFilter:filters[i][1]})

          bool = false;
        }
      }

      return bool;

    })
  },
  dateRangeArr(sDate, numOfDays) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24,
    startDate = new Date(sDate), startDateInMs = startDate.getTime()

    //console.log({numOfDays})

    return [...Array(numOfDays).keys()].map(i => new Date(startDateInMs + i * _MS_PER_DAY).toISOString().slice(0,10))
  },
  dateDiffDays(sDate, eDate) {
    // Discard the time and time-zone information.
    const _MS_PER_DAY = 1000 * 60 * 60 * 24,
    utc1 = Date.UTC(sDate.getFullYear(), sDate.getMonth(), sDate.getDate()),
    utc2 = Date.UTC(eDate.getFullYear(), eDate.getMonth(), eDate.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  },
  reformatDate(oldDate) {
    return parseInt([oldDate.split('-')[2],oldDate.split('-')[0],oldDate.split('-')[1]].join(''))
  },
  irisDate() {
        Date.prototype.GetFirstDayOfWeek = function() {
            return (new Date(this.setDate(this.getDate() - this.getDay() + (this.getDay() == 0 ? -6 : 1) )));
        }
        Date.prototype.GetLastDayOfWeek = function() {
            return (new Date(this.setDate(this.getDate() - this.getDay() + 7)));
        }
        Date.prototype.GetLastSeven = function() {
            const startDay = new Date(),
              //endDay = new Date();
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 7);
            //endDay.setTime(endDay.getTime() - 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }
        Date.prototype.GetLastThirty = function() {
            const startDay = new Date(),
              //endDay = new Date();
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 30);
            //endDay.setTime(endDay.getTime() - 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }
        Date.prototype.GetThisWeek = function() {
            const startDay = this.GetFirstDayOfWeek(),
              endDay = this.GetLastDayOfWeek();
            return ({startDay, endDay});
        }
        Date.prototype.GetThisMonth = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth(), 1),
              endDay = new Date(this.getFullYear(), this.getMonth() + 1, 0);
            return ({ startDay, endDay });
        }
        Date.prototype.GetLastWeek = function() {
            const startDay = this.GetFirstDayOfWeek(),
              endDay = this.GetLastDayOfWeek();
            startDay.setTime(startDay.getTime() - 3600 * 1000 * 24 * 6);
            endDay.setTime(endDay.getTime() - 3600 * 1000 * 24 * 6);
            return ({startDay, endDay});
        }
        Date.prototype.GetLastMonth = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth() - 1, 1),
              endDay = new Date(this.getFullYear(), this.getMonth(), 0);
            startDay.setTime(startDay.getTime() + 3600 * 1000 * 24);
            endDay.setTime(endDay.getTime() + 3600 * 1000 * 24);
            return ({startDay, endDay});
        }
        Date.prototype.GetMonthToDate = function() {
            const startDay = new Date(this.getFullYear(), this.getMonth(), 1),
              //endDay = new Date();
              endDay = new Date(this);
            startDay.setTime(startDay.getTime() + 3600 * 1000 * 24);
            //endDay.setTime(endDay.getTime() - 3600 * 1000 * 24);
            return ({ startDay, endDay });
        }

        const adjDate = new Date();
        adjDate.setTime(adjDate.getTime() - 3600 * 1000 * 8)

        //console.log({adjDate,ogDate:new Date()})

        //return new Date()
        return adjDate
  },
  clearIrisTimeout() {
        clearTimeout(this.session_timeout)
  },
  irisTimeout() {
        const minutes = 90;
        //const minutes = 0.5;
        this.session_timeout = setTimeout(() => { this.confirmSession() }, 1000 * 60 * minutes)
  },
  async logoutIris() {
        const LogOut = await axios.get('logout/').catch(function (error) { return {data: {logout: 'undefined'}} }),
        boolCheck = typeof LogOut.data.logout !== "undefined" ? LogOut.data.logout : 'expired';

        //console.log({boolCheck, store:this, session_router:this.session_router})

        // MUST match [[globals]]
        if (boolCheck) {
            this.propsToSearch = []
            this.tableColumns = []
            this.colWidthDict = false
            this.ogPropsToSearch = false
            this.ogTableColumns = false
            this.userConfirm = false
            this.irisData = false
            this.reset_pass = false
            this.customQuery = false
            this.irisLoader = false
            this.aggregateSelected = 'total'
            this.companySelected = 'all'
            this.siteSelected = ['all']
            this.deviceSelected = 'all'
            this.productSelected = ['all']
            this.subProductSelected = ['all']
            this.exportRows = []
            this.mundialData = []
            this.irisText = 'Last 30 Days'
            this.irisDateRange = []
            this.irisMinMaxDate = []
            this.customMinMaxDate = []
        }

        // Redirect Login (expired sessions)
        if(boolCheck === 'expired'){
            this.session_router.push({'name':'Login'})
            this.session_router = false
            this.clearIrisTimeout()
        }
  },
  async confirmTC(tcForm) {
        // POST Confirm Terms of Use
        const UpdateData = await axios.post('confirmterms/',tcForm),
        boolCheck = UpdateData.data.user;

        if (boolCheck) {
          this.irisData.user = boolCheck
        }
  },
  async valid_reset_pass() {
        // POST Confirm Terms of Use
        const email = this.reset_pass ? this.reset_pass.email : false,
        password = this.reset_pass ? this.reset_pass.password : false,
        ValidResetPass = email && password ? await axios.post('validreset/',{ email, password }) : false,
        boolCheck = ValidResetPass ? ValidResetPass.data.valid_reset : ValidResetPass;

        //console.log({ValidResetPass, boolCheck})

        return boolCheck
  },
  async resetSessionFilters(sessionForm) {
        // POST add filters to Django session
        const UserSession = await axios.post('sessionfilters/',sessionForm), InvalidSess = UserSession.data.invalid_session;

        // Alert session errors
        if (InvalidSess) {
          alert('Session invalid! Please notify Mundial Media admins.')
        }
  },
  async resetIrisPass(resetPassForm) {
        //console.log('_____')
	//console.log('')
        //console.log({resetPassForm})
	//console.log('')
        //console.log('_____')

        // POST InvalidPass
        const ValidatePass = await axios.post('resetpass/',resetPassForm), InvalidPass = ValidatePass.data.invalid_pass;
        this.irisData = InvalidPass

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (InvalidPass) {
          alert(`Please check your email inbox for ${resetPassForm.email}!`)
        }

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
  },
  async setNewPass(setPassForm) {
        //console.log('_____')
	//console.log('')
        //console.log({setPassForm})
	//console.log('')
        //console.log('_____')

        // POST InvalidPass
        const ValidatePass = await axios.post('setnewpass/',setPassForm), InvalidPass = ValidatePass.data.invalid_pass;
        this.irisData = InvalidPass

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (!InvalidPass) {
          this.reset_pass = false
          alert('Password set successfully!')
        }

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
  },
  async confirmSession() {
        // GET Confirm Session
        const SessionData = await axios.get('confirmsession/').catch(function (error) { return {data: {status: 'undefined'}} }),
        boolCheck = typeof SessionData.data.status !== 'undefined' ? SessionData.data.status : false;

        //console.log({boolCheck})

        // Logout Iris (if expired)
        if (!boolCheck) {
          this.logoutIris()
        } else {
          this.clearIrisTimeout()
          this.irisTimeout()
        }
  },
  async loginIris(loginForm) {
        if (loginForm.password) this.irisLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const UserData = await axios.post('login/',loginForm)

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        this.irisData = UserData.data.user ? UserData.data : false
        if (this.irisData) this.irisTimeout()

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.irisLoader = false
  },
  async initMundial() {
        this.irisLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({loginForm})
	//console.log('')
        //console.log('_____')

        // POST UserData
        const MundialData = await axios.get('initdata/'),
        boolCheck = MundialData.data.mundial;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UserData})
        //console.log('_ _ _ _ _ _')

        if (boolCheck) this.irisData.mundial = boolCheck

        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')
        this.irisLoader = false
  },
  async createSystemStatus(mForm) {
        this.irisLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const StatusData = await axios.post('createsystemstatus/',mForm),
        boolCheck = StatusData.data.system_status;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({StatusData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.irisLoader = false
        if (boolCheck) {
            this.irisData.system_status = boolCheck
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async createMundial(mForm) {
        this.irisLoader = true
        //console.log('_____')
	//console.log('')
        //console.log({mForm})
	//console.log('')
        //console.log('_____')

        // POST ProfileData
        const ProfileData = await axios.post('createmundial/',mForm),
        boolCheck = ProfileData.data.users_list;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({ProfileData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.irisLoader = false
        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.irisData.users_list = boolCheck
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async updateIris(uForm) {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('irisupdate/',uForm),
        boolCheck = UpdateData.data.user;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.irisData.user = boolCheck
            this.irisData.users_list = UpdateData.data.users_list

            return true
        } else {
            return false
        }
  },
  async updatePlatformUser(uForm) {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const UpdateData = await axios.post('updateuser/',uForm),
        boolCheck = UpdateData.data.users_list;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({UpdateData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck === 'EXISTING_USERNAME') {
            return boolCheck
        } else if (boolCheck) {
            this.irisData.users_list = boolCheck
            return true
        } else {
            return false
        }
  },
  async deletePlatformUser(mForm) {
        this.irisLoader = true
        //console.log('_____')
        //console.log('')
        //console.log({mForm})
        //console.log('')
        //console.log('_____')

        // POST ProfileData
        const MundialData = await axios.post('deleteuser/',mForm),
        boolCheck = MundialData.data.users_list;

        // Update State

        //console.log('_ _ _ _ _ _')
        //console.log({MundialData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        this.irisLoader = false
        if (boolCheck) {
            this.irisData.users_list = boolCheck
            //alert(boolCheck)
            return true
        } else {
            return false
        }
  },
  async customMundial() {
        //console.log('_____')
	//console.log('')
        //console.log({uForm})
	//console.log('')
        //console.log('_____')

        // POST UpdateData
        const sdate = this.customMinMaxDate[0].toISOString().split('T')[0], edate = this.customMinMaxDate[1].toISOString().split('T')[0],
        date_range = [sdate, edate],
        customData = await axios.post('customdata/',{ date_range }),
        boolCheck = customData.data.custom;

        // Return Data

        //console.log('_ _ _ _ _ _')
        //console.log({customData})
        //console.log('_ _ _ _ _ _')
        //console.log(this)
        //console.log('_ _ _ _ _ _')

        if (boolCheck) {
            return boolCheck
        } else {
            return this.irisData.mundial
        }
  },
  async sessionLogin(session_router) {
        await this.loginIris({email:'sessionid', password:false})

        //console.log({store, session:true})

        // Redirect
        if(store.irisData){
          session_router.push({'name':'Overview'})
        }
  },
  download(data) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: 'text/csv' });
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // Passing the blob downloading url
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    const ahoraMismo = this.irisDate();
    a.setAttribute('download', `publisher_report_${this.irisText.toLowerCase().replace(' ','_')}__${ahoraMismo.toISOString()}.csv`);
    // Performing a download with click
    a.click()
  },
  csvmaker(exportArr) {
    // Empty array for storing the values
    let csvRows = [], headers = Object.keys(exportArr[0]),
    dataArr = JSON.parse(JSON.stringify(exportArr)),
    dataLen = dataArr.length;
    // Headers is basically a keys of an
    // object which is id, name, and
    // profession
    const adminRemoveArr = this.adminRemoveArr, adminRemoveSet = new Set(adminRemoveArr),
    clientRemoveArr = this.clientRemoveArr, clientRemoveSet = new Set(clientRemoveArr),
    irisRemoveArr = this.irisRemoveArr, irisRemoveSet = new Set(irisRemoveArr),
    is_staff = this.irisData.user.is_staff,
    is_admin = this.irisData.user.is_superuser;
    if(is_staff && !is_admin) {
       headers = headers.filter(function (item) {
          return !irisRemoveSet.has(item);
       });
    } else if (!is_admin) {
       headers = headers.filter(function (item) {
          return !clientRemoveSet.has(item);
       });
    } else {
       headers = headers.filter(function (item) {
          return !adminRemoveSet.has(item);
       });
    }
    // As for making csv format, headers
    // must be separated by comma and
    // pushing it into array
    const cleanHeaders = [],
    colsDict = this.colsDict,
    headLen = headers.length;
    for (let h = 0; h < headLen; h++) {
      const cleanHead = colsDict[headers[h]];
      cleanHeaders.push(cleanHead)
    }
    csvRows.push(cleanHeaders.join(','));
    // Pushing Object values into array
    // with comma separation
    for(let d = 0; d < dataLen; d++) {
       let dataRow = dataArr[d];
       if(is_staff && !is_admin) {
          irisRemoveArr.forEach(e => delete dataRow[e])
       } else if (!is_admin) {
          clientRemoveArr.forEach(e => delete dataRow[e])
       } else {
          adminRemoveArr.forEach(e => delete dataRow[e])
       }

       // TEST (maintained for testing purposes)
       //console.log({dataRow})

       // Add quotes to preserve commas during CSV export
       for(let h = 0; h < headLen; h++) {
         const tmpHeader = headers[h];
         dataRow[tmpHeader] = `\"${dataRow[tmpHeader]}\"`
       }

       //console.log({dataRow})

       const val = Object.values(dataRow).join(',');
       csvRows.push(val)
    }
    // Returning the array joining with new line
    return csvRows.join('\n')
  },
  queriedData(searchQuery) {
        if (!searchQuery.length) {
          //return this.irisData.mundial
          //return this.mundialData
          return this.exportRows
        }
        const propsToSearch = this.propsToSearch,
        lowerSearchQuery = searchQuery.toLowerCase(),
        propLen = propsToSearch.length;
        //let result = this.irisData.mundial
        //let result = this.mundialData
        let result = this.exportRows
          .filter((row) => {
            let isIncluded = false


            // TEMP
            //console.log({row})



            for(let k = 0; k < propLen; k++) {
              const tmpKey = propsToSearch[k];

              // TEST (maintained for testing purposes)
              //console.log({key})
              //console.log(row[key])
              //console.log(row[key].toLowerCase())

              let rowValue = row[tmpKey].toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        return result
  },
  async exportIris(searchQuery) {
        this.irisLoader = true

        const csvData = this.queriedData(searchQuery), csvFile = this.csvmaker(csvData);
        await this.download(csvFile);

        this.irisLoader = false
  },
  sortCustom(prop) {
    //console.log({prop})

    function toFloat(num) {
      //console.log({num})
      if (prop === 'date') num = [num.split('-')[2],num.split('-')[0],num.split('-')[1]].join('')

      return Number( num ? num.replace('$','').replace(',','').replace('%','') : 0 )
    }

    const custom = function(a, b) {

      //console.log({a,b})

      const bool = isNaN( toFloat(b[prop]) - toFloat(a[prop]) );

      if(bool) {
        if (a[prop].toUpperCase() < b[prop].toUpperCase()) {
          return 1;
        }
        if (a[prop].toUpperCase() > b[prop].toUpperCase()) {
          return -1;
        }
        return 0;
      } else {
        return toFloat(b[prop]) - toFloat(a[prop]);
      }
    }
    return custom;
  }
})
